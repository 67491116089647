<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon @click="editRecord" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"/>
  </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord() {
      this.$router.push(`/a/services/${this.params.data.id}/edit`).catch(() => {
      });
    },
    showError(exception) {

      this.$vs.loading.close();

      let error = 'An unknown error occurred while archive this service';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to archive service',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
};
</script>
