<template>
  <div class="flex items-center">
    {{this.params.value ? 'Yes' : 'No'}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    countPrices() {
      return (this.params.data || {price_ids: []}).price_ids.length;
    },
    countChildren() {
      return (this.params.data || {children_ids: []}).children_ids.length;
    },
  },
};
</script>
